import "~/components/shared/SEOBlock.css";
import "./ResultsSEO.css";

export default function ResultsSEO() {
  return (
    <>
      <div class="seo-block" data-test="seo-block">
        <div class="content-part">
          <h2 class="main-title">
            Avec Cogedim, trouvez le bien neuf de vos rêves
          </h2>
          <div class="text">
            <p>
              Trouver le promoteur qui saura vous accompagner, vous conseiller
              et vous guider tout au long de votre projet est l’une des étapes
              les plus importantes dans la réussite de votre investissement
              immobilier. Cogedim s’engage à vous fournir un accompagnement
              personnalisé, de la recherche du bien jusqu’à plusieurs années
              après la livraison, pour vous faire vivre une expérience agréable.
            </p>
            <h2>Les avantages d’investir dans l’immobilier neuf</h2>
            <p>
              L’immobilier neuf est un marché sur lequel les risques sont assez
              faibles et où les rendements s’avèrent particulièrement
              intéressants. C’est d’ailleurs le placement préféré des Français.
              Aujourd’hui, acquérir un logement neuf offre des avantages
              incomparables&nbsp;: sécurité, frais réduits, dispositifs fiscaux
              intéressants et plus encore. Découvrez en 4 points, les avantages
              de l’investissement dans le neuf.
            </p>
            <h3>Les frais de notaire réduits</h3>
            <p>
              L’acquéreur d’un bien immobilier, neuf ou ancien, doit s’acquitter
              des «&nbsp;frais de notaire&nbsp;» ou «&nbsp;frais
              d’acquisition&nbsp;», en plus du prix du logement. Ces frais sont
              particulièrement faibles lorsqu’il s’agit d’un bien neuf&nbsp;:
              ils représentent 2 à 3&nbsp;% du prix d’achat, alors qu’ils
              peuvent s’élever à 8&nbsp;% pour un logement ancien. Une économie
              intéressante et non négligeable.
            </p>
            <p>
              Les frais d’acquisition se décomposent de la manière
              suivante&nbsp;:
            </p>
            <ul>
              <li>80&nbsp;% sont des impôts et taxes&nbsp;;</li>
              <li>
                10&nbsp;% correspondent aux frais de débours engendrés par la
                rémunération d’intervenants externes (experts, géomètres, etc.)
                et le paiement de documents tels que la publication de
                vente&nbsp;;
              </li>
              <li>
                10&nbsp;% concernent les émoluments, autrement dit la
                rémunération réelle du notaire. Ces derniers sont proportionnels
                au prix de vente, sur lequel est appliqué un pourcentage
                dégressif.
              </li>
            </ul>
            <h3>Les aides de l’Etat dédiées à l’achat dans le neuf</h3>
            <p>
              Le prêt à taux zéro (PTZ) est un crédit gratuit en faveur de
              l’investissement immobilier dans le neuf qui aide les
              primo-accédants à acquérir leur résidence principale. Il peut
              financer jusqu’à 50&nbsp;% du montant du bien. Il est accessible
              aux particuliers de classe moyenne (il concerne plus de 80&nbsp;%
              des ménages grâce à des plafonds de ressource très élevés) qui
              achètent un bien immobilier pour la première fois. Condition
              importante, le logement doit être la résidence principale de
              l’acquéreur, ce qui signifie qu’il doit être occupé au moins 8
              mois par an. Ce prêt peut donc faciliter l’achat d’un logement
              vendu sur plans, d’un bien neuf jamais habité, d’un terrain ou la
              construction d’une maison.
            </p>
            <h3>Dispositifs fiscaux</h3>
            <h4>
              Le{" "}
              <strong>statut de Loueur Meublé Non Professionnel (LMNP)</strong>
            </h4>
            <p>
              Le{" "}
              <a
                href="/investir/les-solutions-pour-investir-dans-le-neuf/le-dispositif-lmnp/"
                target="_blank"
              >
                statut LMNP
              </a>{" "}
              est idéal pour générer des revenus locatifs avec une fiscalité
              avantageuse. Vous pouvez opter pour le régime micro-BIC, qui
              applique un abattement de 50&nbsp;% sur vos recettes, ou le régime
              réel, qui vous permet de déduire vos charges, intérêts d’emprunt
              et d’amortir le bien, réduisant ainsi votre base imposable.
            </p>
            <h4>
              La <strong>nue-propriété</strong>
            </h4>
            <p>
              L’achat en{" "}
              <a
                href="/investir/les-solutions-pour-investir-dans-le-neuf/l-achat-en-nue-propriete/"
                target="_blank"
              >
                nue-propriété
              </a>{" "}
              constitue une solution patrimoniale performante. En dissociant
              l’usufruit (utilisation du bien) et la propriété, ce dispositif
              vous permet d’acquérir un logement à prix réduit tout en
              bénéficiant d’une fiscalité allégée. Une fois l’usufruit réuni
              après une période déterminée, le bien retrouve toute sa valeur,
              constituant un investissement rentable sur le long terme.
            </p>
            <h3>
              Acheter sur plan&nbsp;: un investissement confortable et sécurisé
            </h3>
            <p>
              Plus qu’une simple question de goût, faire le choix du neuf, c’est
              investir dans un bien immobilier sécurisant, confortable et
              économique.
            </p>
            <p>
              D’abord, vivre dans un logement neuf permet de choisir la
              disposition, l’ameublement et la décoration. C’est donc pouvoir
              faire le choix d’équipements qui amélioreront le quotidien et qui
              permettent de réaliser des économies&nbsp;: chaudières dernières
              générations, robinetterie économe, volets intelligents qui se
              ferment en fonction de la température extérieure ou lumières
              programmables. Sans parler de la connectivité qui permet de suivre
              la consommation et de régler le chauffage ou la luminosité à
              distance.
            </p>
            <p>
              Aussi, avec des normes et des exigences de constructions qui ne
              cessent d’évoluer, opter pour le neuf, c’est l’assurance de ne pas
              avoir à réaliser des travaux à courte échéance. C’est également
              profiter de hautes performances d’isolation thermique et phonique
              pour un confort idéal.
            </p>
            <h2>
              Pourquoi acheter son logement auprès d’un promoteur
              immobilier&nbsp;?
            </h2>
            <p>
              Le promoteur est un expert du marché immobilier. Afin de
              comprendre pourquoi sa présence facilite le parcours qui mène à la
              concrétisation de vos projets, il est important de mieux connaître
              son rôle.
            </p>
            <h3>
              Les missions du promoteur immobilier dans un projet immobilier
            </h3>
            <p>
              Véritable spécialiste de l’immobilier, le promoteur est présent
              sur toutes les étapes de la construction d’un bâtiment, qu’il soit
              destiné à l’habitat ou au commerce. En amont, il procède à la
              recherche du terrain, réalise l’étude financière, conçoit les
              plans, et entreprend l’ensemble des démarches administratives. En
              tant que maître d’œuvre, il supervise ensuite le chantier du début
              à la livraison.
            </p>
            <h3>Les avantages de passer par un promoteur</h3>
            <h4>Profiter du savoir d’un professionnel</h4>
            <p>
              Le promoteur maîtrise les marchés immobiliers aux niveaux local et
              national&nbsp;: l’offre et la demande, les opportunités, les codes
              et les contraintes. Son rôle premier est de proposer à ses clients
              le meilleur projet en fonction de leurs attentes, de leurs besoins
              et de leurs capacités de financement. D’ailleurs, autre avantage
              de choix&nbsp;: il est au fait de toutes les aides et les
              différents dispositifs qui faciliteront l’acquisition (PTZ,
              subventions diverses, etc.). En fonction du profil des futurs
              propriétaires, il peut leur permettre de réaliser des économies
              importantes.
            </p>
            <h4>
              Cogedim, une expérience unique pour l’achat d’une maison ou d’un{" "}
              <a
                href="/habiter/mon-projet-immobilier/mon-premier-achat/appartement-neuf/"
                target="_blank"
              >
                appartement neufs
              </a>
            </h4>
            <p>
              Investir, surtout quand pour la première fois, peut être une
              grande source de stress, voire de véritable angoisse. Cogedim vous
              accompagne pour franchir toutes les étapes de l’achat immobilier
              en toute sérénité. À partir du premier rendez-vous et jusqu’à
              plusieurs années après la remise des clés, nous sommes à vos côtés
              et nous répondons à tous vos besoins. Acheter un logement sur
              plans nécessite de pouvoir bénéficier d’outils de projection
              performants afin de s’assurer d’une conformité exacte du logement
              avant et après construction. Cogedim met à la disposition de ses
              clients un ensemble d’outils tels que les plans 3D, les visites
              virtuelles, et même une visite immersive de logements reproduits
              en taille réelle dans nos Stores Cogedim.
            </p>
            <h4>
              L’assurance de recevoir un produit de qualité sans frais
              additionnels
            </h4>
            <p>
              Faire appel à un promoteur immobilier, c’est aussi la certitude
              que son budget sera respecté et l’assurance de recevoir un
              logement neuf, conforme aux dernières normes en vigueur.
            </p>
            <h2>
              Labels, certifications et réglementations d’une résidence neuve
            </h2>
            <p>
              Avant de se lancer dans l’achat d’un logement neuf, il est
              essentiel de comprendre les différents labels et certifications
              qui garantissent la qualité et les performances énergétiques des
              bâtiments. Ces distinctions, spécifiques au secteur immobilier,
              jouent un rôle clé dans l’assurance d’économies d’énergie et d’un
              confort de vie optimal. Tour d’horizon.
            </p>
            <h3>
              <strong>
                La réglementation environnementale 2020 (RE2020) et le seuil
                2025&nbsp;: des normes encore plus exigeantes
              </strong>
            </h3>
            <h4>
              <strong>RE2020 (en vigueur depuis 2021)</strong>
            </h4>
            <p>
              Cette norme remplace la RT2012 en mettant l’accent sur la
              réduction de l’impact environnemental des nouvelles constructions.
              Les bâtiments doivent désormais être{" "}
              <strong>à énergie positive</strong>, c’est-à-dire produire plus
              d’énergie qu’ils n’en consomment grâce à des équipements comme des
              panneaux solaires. La RE2020 introduit également des critères de{" "}
              <strong>confort d’été</strong>, visant à limiter la surchauffe
              pendant les périodes de forte chaleur, et réduit les émissions de
              gaz à effet de serre liées aux matériaux de construction.
            </p>
            <h4>
              <strong>RE2020 seuil 2025</strong>
            </h4>
            <p>
              À partir de 2025, des exigences encore plus strictes s’appliquent
              pour les bâtiments neufs. L’accent est mis sur l’amélioration des
              performances énergétiques, la réduction supplémentaire des
              émissions carbone et l’intégration accrue de matériaux biosourcés
              (comme le bois). Ces avancées garantissent des logements encore
              plus durables et alignés avec les objectifs de transition
              énergétique.
            </p>
            <h3>
              <strong>
                Labels énergétiques&nbsp;: des repères pour des logements
                performants
              </strong>
            </h3>
            <h4>
              <strong>
                Labels HPE (Haute Performance Énergétique) et THPE (Très Haute
                Performance Énergétique)
              </strong>
            </h4>
            <p>
              Ces labels indiquent que les logements consomment respectivement
              10&nbsp;% et 20&nbsp;% d’énergie en moins que les seuils fixés par
              la RT2012. Bien que ces labels soient moins utilisés depuis la
              mise en place de la RE2020, ils restent un gage de performance
              pour les bâtiments répondant à des normes élevées.
            </p>
            <h4>
              <strong>Label Effinergie+</strong>
            </h4>
            <p>
              Délivré par l’association Effinergie, ce label évalue la
              performance énergétique globale d’un bâtiment, y compris
              l’étanchéité à l’air et l’efficacité des systèmes de ventilation.
            </p>
            <h3>
              <strong>Certifications pour une qualité globale</strong>
            </h3>
            <h4>
              <strong>Certification NF Habitat / NF Habitat HQE</strong>
            </h4>
            <p>
              Créée en 2015, cette certification atteste non seulement de la
              qualité de construction, mais aussi des critères de confort et de
              service. Elle est délivrée sur la base d’un cahier des charges
              rigoureux qui garantit des performances techniques, énergétiques
              et environnementales optimales.
            </p>
            <h3>
              <strong>
                Pourquoi ces labels et certifications comptent&nbsp;?
              </strong>
            </h3>
            <p>
              Les réglementations et certifications comme la RE2020 ou NF
              Habitat HQE sont des gages de qualité et de durabilité. En tant
              qu’acheteur, elles vous assurent des économies d’énergie, un
              confort thermique optimal, et une valorisation patrimoniale accrue
              pour votre bien immobilier. En choisissant un programme neuf avec
              Cogedim, vous bénéficiez de ces normes exigeantes, synonymes de
              sérénité et d’investissement responsable.
            </p>
            <h3>Les avantages de choisir un immeuble neuf certifié</h3>
            <p>
              Que ce soit dans le cadre d’un investissement locatif ou pour une
              résidence principale, l’achat d’un logement neuf labellisé ou
              certifié permet de profiter d’un lieu de vie peu gourmand en
              énergie. Par conséquent, ce sont des économies garanties sur les
              factures de gaz et d’électricité à la fin du mois. En tant que
              propriétaire, c’est aussi un atout intéressant qui pourra être
              valorisé dans le cas d’une revente. Enfin, investir dans un
              logement neuf certifié encourage le recours à ce type de
              construction plus respectueuse de l’environnement, moins polluante
              et plus verte. Aujourd’hui, en France, les programmes immobiliers
              neufs ne demandent qu’à être soutenus.
            </p>
            <h2>
              Comment réussir son investissement dans l’immobilier neuf&nbsp;?
            </h2>
            <p>
              C’est la question que tout futur propriétaire de logement neuf se
              pose&nbsp;: comment faire en sorte que son investissement rapporte
              ou qu’il constitue un placement fiable&nbsp;?
            </p>
            <h3>Emplacement, surface&nbsp;: bien choisir son logement</h3>
            <p>
              La situation géographique d’un logement a une influence directe
              sur sa valeur. C’est pourquoi opter pour une ville très prisée
              pour investir dans l’immobilier peut revenir très cher. Il
              convient parfois de choisir des localités proches affichant des
              prix souvent plus bas. Aussi, que la gestion du logement soit
              assurée par une entreprise spécifique ou non, il est recommandé
              d’investir dans un secteur relativement proche de chez soi afin de
              pouvoir consulter les prix pratiqués, les spécificités du marché,
              les aides et dispositifs régionaux, etc.
            </p>
            <p>
              La surface est un des critères essentiels d’un achat immobilier.
              Pour une meilleure rentabilité, il est souvent plus intéressant
              d’investir dans une petite surface&nbsp;: prix d’achat moins
              élevé, prix au m<sup>2</sup> plus important au moment de la mise
              en location, biens particulièrement recherchés dans les grandes
              métropoles françaises.
            </p>
            <h3>Tenir compte de la fiscalité</h3>
            <p>
              Être parfaitement renseigné sur les différentes dispositions
              fiscales qui encadrent l’achat d’un bien neuf dans l’optique de le
              louer est primordial. Il est en effet possible que des aides ou
              des réductions d’impôts soient accessibles si la situation
              personnelle et économique de l’investisseur répond aux conditions
              attendues, ce qui favoriserait la rentabilité de l’investissement.
            </p>
            <h3>Assurer la gestion locative</h3>
            <p>
              Une fois que le logement est acquis, il reste encore quelques
              étapes avant de pouvoir bénéficier d’un revenu complémentaire.
              Pour le mettre en location, il existe deux possibilités&nbsp;:
              assurer la gestion soi-même ou confier cette tâche à un
              professionnel comme Altarea Gestion Immobilière. Un investissement
              locatif nécessite du temps, mais des solutions existent. Il est
              nécessaire d’en avoir connaissance pour prendre la meilleure
              décision en fonction de sa situation personnelle. Cogedim offre à
              l’ensemble de ses clients la possibilité de bénéficier d’une
              gestion locative avantageuse et complète pour un placement sans
              aucun soucis de gestion.
            </p>
            <h2>
              Découvrez nos nouveaux programmes immobilier neuf dans toute la
              France
            </h2>
            <ul>
              <li>
                Nos{" "}
                <a href="/ile-de-france/" target="_blank">
                  programmes immobilier neuf en île de France
                </a>
              </li>
              <li>
                Nos{" "}
                <a
                  href="/provence-alpes-cote-d-azur/bouches-du-rhone-13/marseille/"
                  target="_blank"
                >
                  programmes immobilier neuf à Marseille
                </a>
              </li>
              <li>
                Nos{" "}
                <a href="/auvergne-rhone-alpes/rhone-69/lyon/" target="_blank">
                  programmes immobilier neuf à Lyon
                </a>
              </li>
              <li>
                Nos{" "}
                <a href="/occitanie/herault-34/montpellier/" target="_blank">
                  programmes immobilier neuf à Montpellier
                </a>
              </li>
              <li>
                Nos{" "}
                <a href="/hauts-de-france/nord-59/lille/" target="_blank">
                  programmes immobilier neuf à Lille
                </a>
              </li>
              <li>
                Nos{" "}
                <a
                  href="/provence-alpes-cote-d-azur/alpes-maritimes-06/nice/"
                  target="_blank"
                >
                  programmes immobilier neuf à Nice
                </a>
              </li>
              <li>
                Nos{" "}
                <a href="/occitanie/haute-garonne-31/toulouse/" target="_blank">
                  programmes immobilier neuf à Toulouse
                </a>
              </li>
              <li>
                Nos{" "}
                <a
                  href="/nouvelle-aquitaine/gironde-33/bordeaux/"
                  target="_blank"
                >
                  programmes immobilier neuf à Bordeaux
                </a>
              </li>
              <li>
                Nos{" "}
                <a href="/grand-est/bas-rhin-67/strasbourg/" target="_blank">
                  programmes immobilier neuf à Strasbourg
                </a>
              </li>
              <li>
                Nos{" "}
                <a
                  href="/auvergne-rhone-alpes/haute-savoie-74/annecy/"
                  target="_blank"
                >
                  programmes immobilier neuf à Annecy
                </a>
              </li>
              <li>
                Nos{" "}
                <a
                  href="/pays-de-la-loire/loire-atlantique-44/nantes/"
                  target="_blank"
                >
                  programmes immobilier neuf à Nantes
                </a>
                <br />
              </li>
            </ul>
          </div>

          <div class="spacer" />

          <h2 class="main-title">Foire aux questions</h2>
          <div class="text">
            <h3>Comment avoir les frais de notaire offerts&nbsp;?</h3>
            <p>
              Chez Cogedim, nous proposons régulièrement des offres
              exceptionnelles, notamment la prise en charge des frais de notaire
              pour certaines résidences neuves. Ces offres sont généralement
              limitées dans le temps et concernent des programmes spécifiques.
              Pour en profiter, il est essentiel de rester informé&nbsp;:
              inscrivez-vous à notre newsletter et consultez régulièrement notre
              site pour ne manquer aucune opportunité. N’hésitez pas également à
              contacter nos conseillers pour connaître les promotions en cours.
            </p>
            <h3>Comment bénéficier de la tva réduite à 5,5&nbsp;%&nbsp;?</h3>
            <p>
              La TVA réduite à 5,5&nbsp;% s’applique à certains logements neufs
              situés dans des zones ANRU (Agence Nationale pour la Rénovation
              Urbaine) ou des Quartiers Prioritaires de la Politique de la Ville
              (QPV). Pour en bénéficier, plusieurs conditions doivent être
              respectées&nbsp;:
            </p>
            <ul>
              <li>Le logement doit être votre résidence principale.</li>
              <li>
                Vos revenus ne doivent pas dépasser les plafonds fixés par la
                loi.
              </li>
            </ul>
            <p>
              Retrouvez la liste de nos{" "}
              <a
                href="/votre-futur-bien/par-opportunite/nos-programmes-tva-reduite/"
                target="_blank"
              >
                résidences éligibles à la TVA réduite
              </a>
              .
            </p>
            <h3>Comment choisir un programme immobilier neuf&nbsp;?</h3>
            <p>
              Pour choisir un programme immobilier neuf adapté à vos besoins,
              tenez compte des critères suivants&nbsp;:
            </p>
            <ul>
              <li>
                <strong>Emplacement&nbsp;:</strong> privilégiez une zone bien
                desservie avec des infrastructures de qualité.
              </li>
              <li>
                <strong>Budget&nbsp;:</strong> définissez un budget clair
                incluant le coût du bien et les éventuelles charges annexes.
              </li>
              <li>
                <strong>Objectif&nbsp;:</strong> est-ce votre résidence
                principale, un investissement locatif ou secondaire&nbsp;?
              </li>
              <li>
                <strong>Services proposés&nbsp;:</strong> vérifiez les
                prestations incluses (finitions, espaces verts, domotique).
              </li>
            </ul>
            <p>
              Cogedim propose une large sélection de programmes répondant à
              différents profils. Contactez-nous pour un accompagnement
              personnalisé dans votre choix.
            </p>
            <h3>Comment connaître les futurs programmes immobiliers&nbsp;?</h3>
            <p>
              Pour rester informé des nouveaux projets Cogedim, vous
              pouvez&nbsp;:
            </p>
            <ul>
              <li>
                Vous inscrire à nos communications pour recevoir les annonces en
                avant-première, par email ou SMS.
              </li>
              <li>
                Consulter régulièrement notre site dans la section dédiée aux{" "}
                <a
                  href="/votre-futur-bien/par-opportunite/derniers-lancements/"
                  target="_blank"
                >
                  derniers lancements
                </a>
                .
              </li>
              <li>
                Nous suivre sur nos réseaux sociaux où nous partageons toutes
                les nouveautés.
              </li>
              <li>
                Participer à nos événements de lancement, souvent accompagnés
                d’offres exclusives.
              </li>
            </ul>
            <p>
              Avec Cogedim, soyez parmi les premiers à découvrir des résidences
              d’exception et à réserver votre logement idéal.
            </p>
            <h3>C’est quoi un programme immobilier neuf&nbsp;?</h3>
            <p>
              Un <strong>programme neuf</strong> désigne un ensemble immobilier
              conçu et construit récemment ou en cours de construction. Il
              comprend généralement des appartements, des maisons ou des locaux
              à usage mixte proposés à la vente avant ou après l’achèvement des
              travaux. Ces logements respectent les dernières normes
              environnementales (comme la réglementation RE2020) et bénéficient
              souvent de garanties avantageuses, telles que&nbsp;:
            </p>
            <ul>
              <li>
                <strong>La garantie décennale</strong>&nbsp;: Protection contre
                les défauts de construction pendant 10 ans.
              </li>
              <li>
                <strong>La garantie de parfait achèvement</strong>&nbsp;: Prise
                en charge des éventuelles malfaçons pendant un an après
                livraison.
                <br />
              </li>
            </ul>
            <p>
              Acheter un bien dans un programme neuf offre de nombreux
              bénéfices&nbsp;: économies d’énergie, frais de notaire réduits, et
              personnalisation des aménagements intérieurs.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
